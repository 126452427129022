import React from 'react'
import Object from './usage/Object'
import Point from './usage/Point'
import Option from './usage/Option'
import Description from './Description'

export default function IndustrialFacilities({ language }) {
    const titles = {
        pl: "Obiekty przemysłowe",
        en: "Industrial facilities",
        tt: "Промышленные объекты"
    }

    const descriptions = {
        pl: `Szczególne warunki, jakie panują w obiektach przemysłowych, sprawiają, że użycie tradycyjnej izolacji może okazać się nieskuteczne lub nieekonomiczne. 
            Dlatego coraz większym zainteresowaniem cieszą się materiały o najwyższych parametrach termoizolacyjnych, mogące przez długie lata służyć bezawaryjnie, 
            przez zachowanie swoich najważniejszych parametrów użytkowych. Dla każdego inwestora ważna jest trwałość pozwalająca na uniknięcie uciążliwych konserwacji, 
            częstych remontów oraz napraw. Firma MARPANEL oferuje szeroką gamę nowoczesnych płyt warstwowych ściennych, również dedykowanych do izolacji chłodni/mroźni, oraz dachowych z rdzeniem poliizocyjanurowym (PIR).`,
        en: "Special conditions in industrial facilities mean that the use of traditional insulation may prove ineffective or uneconomical. That is why materials with the highest thermal insulation parameters are becoming more and more popular, which can serve for many years without fail by maintaining their most important functional parameters. It is important for every investor to avoid complicated maintenance, frequent renovations and repairs.",
        tt: "Особые условия, которые преобладают на промышленных объектах, делают, что использование традиционной изоляции может оказаться неэффективным или неэкономичным. Вот почему материалы с самыми высокими параметрами теплоизоляции становятся все более популярными, они могут служить безаварийно долгие годы, сохраняя свои важнейшие функциональные параметры. Для каждого инвестора важна долговечность,позволяющая избежать трудоемкого обслуживания, частых ремонтов и починок"
    }

    const points = {
        dachy_warstwowe: {
            pl: [
                {title: "Dachy z płyt warstwowych", name: "Dach z płyt warstwowych - kalenica", path: "/dach-z-plyt-warstwowych-kalenica"},
                {name: "Dach z płyt warstwowych - połać", path: "/dach-z-plyt-warstwowych-polac"},
                {name: "Dach z płyt warstwowych - okap", path: "/dach-z-plyt-warstwowych-okap"},
            ],
            en: [
                {title: "Roof made of sandwich panels", name: "Roof made of sandwich panels - ridge", path: "/en/Roof-made-of-sandwich-panels-ridge"},
                {name: "Roof made of sandwich panels - slope", path: "/en/roof-made-of-sandwich-panels-slope-2/"},
                {name: "Roof made of sandwich panels - hood", path: "/en/roof-made-of-sandwich-panels-slope/"},
            ],
            tt: [
                {title: "Крыши из сэндвич панелей", name: "Крыша из сэндвич панелей -  конек", path: "/tt/крыша-из-сэндвич-панелей-конек"},
                {name: "Крыша из сэндвич панелей - скат крыши", path: "/tt/крыша-из-сэндвич-панелей---скат-крыши"},
                {name: "Крыша из сэндвич панелей - карниз", path: "/tt/крыша-из-сэндвич-панелей-карниз"},
            ]
        },
        dachy_izolowane: {
            pl: [
                {title: "Dachy izolowane panelami termoizolacyjnymi termPIR", name: "Dach zielony", path: "/dach-zielony"},
                {name: "Dach balastowy", path: "/dach-balastowy"},
                {name: "Dach na warstwie nośnej z blach stalowych", path: "/dach-na-warstwie-nosnej-z-blach-stalowych/"},
                {name: "Dach na podłożu betonowym", path: "/dach-na-podlozu-betonowym/"},
            ],
            en: [
                {title: "Roofs insulated with termPIR thermal insulation panels", name: '"Green" roof', path: "/en/green-roof/"},
                {name: "Ballast roof", path: "/en/Ballast-roof/"},
                {name: "Roof on a supporting layer", path: "/en/Roof on a supporting layer"},
                {name: "Roof on a concrete base", path: "/en/Roof-on-a-concrete-base"},
            ],
            tt: [
                {title: "Крыши с изолированные теплоизоляционными панелями termPIR", name: '"Зеленая" крыша', path: "/tt/зеленая-крыша"},
                {name: "Балластная крыша", path: "/tt/балластная-крыша"},
                {name: "Кровля на несущем слое из стальных листов", path: "/tt/кровля-на-несущем-слое-из-стальных-лис"},
                {name: "Крыша на бетонном основании", path: "/tt/крыша-на-бетонном-основании"},
            ]
        },
        sciany_zewnetrzne: {
            pl: [
                {title: "Ściany zewnętrzne", name: "Ściana zewnętrzna - poziomy układ płyt", path: "/sciana-zewnetrzna-poziomy-uklad-plyt/"},
                {name: "Ściana zewnętrzna - pionowy układ płyt", path: "/sciana-zewnetrzna-pionowy-uklad-plyt/"},
            ],
            en: [
                {title: "External walls", name: "Outer wall - horizontal arrangement of plates", path: "/en/outer-wall---horizontal-arrangement-of-plates"},
                {name: "Outer wall - vertical arrangement of plates", path: "/en/outer-wall---vertical-arrangement-of-plates"},
            ],
            tt: [
                {title: "Наружные стены", name: "Наружная стена - горизонтальное расположение панелей", path: "/tt/наружная-стена-горизонтальное-распо"},
                {name: "Наружная стена - вертикальное расположение панелей", path: "/tt/наружная-стена-вертикальное-располо"},
            ]
        },
        sciany_dzilowe: {
            pl: [
                {title: "Ściany działowe", name: "Ściana działowa - widok od strony płyt", path: "/sciana-dzialowa-widok-od-strony-plyt/"},
            ],
            en: [
                {title: "Partition walls", name: "Partition wall - view from the side of the plates", path: "/en/partition-wall---view-from-the-side-of-the-plates"},
            ],
            tt: [
                {title: "Перегородки", name: "Перегородка - вид со стороны панелей", path: "/tt/перегородка---вид-со-стороны-панелей"},
            ]
        },
        sufity: {
            pl: [
                {title: "Sufit z płyt warstwowych", name: "Sufit - widok z dołu", path: "/sufit-widok-z-dolu/"},
                {name: "Sufit - widok z góry na konstrukcję", path: "/sufit-widok-z-gory-na-konstrukcje/"},
            ],
            en: [
                {title: "Ceilings made of sandwich panels", name: "Ceiling - bottom view", path: "/en/ceiling---bottom-view"},
                {name: "Ceiling - top view of the structure", path: "/en/ceiling---top-view-of-the-structure"},
            ],
            tt: [
                {title: "Потолки из сэндвич панелей", name: "Потолок - вид снизу", path: "/tt/потолок---вид-снизу"},
                {name: "Потолок - вид сверху на конструкцию", path: "/tt/потолок-вид-сверху-на-конструкцию"},
            ]
        },
        posadzki: {
            pl: [
                {title: "Posadzki", name: "Podłoga na gruncie - izolacja dwuwarstwowa", path: "/podloga-na-gruncie-izolacja-dwuwarstwowa/"},
                {name: "Podłoga na gruncie - izolacja jednowarstwowa", path: "/podloga-na-gruncie-izolacja-jednowarstwowa/"},
            ],
            en: [
                {title: "Floor on the ground", name: "Floor on the ground - double layer insulation", path: "/en/floor-on-the-ground---double-layer-insulation"},
                {name: "Floor on the ground - single layer insulation", path: "/en/floor-on-the-ground---single-layer-insulation"},
            ],
            tt: [
                {title: "Полы", name: "Пол на земле - двухслойная изоляция", path: "/tt/пол-на-земле---двухслойная-изоляция"},
                {name: "Пол на земле - однослойная изоляция", path: "/tt/пол-на-земле---однослойная-изоляция"},
            ]
        }
    }

    return (
        <>
            <Description>
                <h1>{titles[language]}</h1>
                <p>{descriptions[language]}</p>
            </Description>
            <Object
                languge={language}
                image="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/przemyslowe.png"
            >
                <Point
                    title={points.dachy_warstwowe[language][0].title}
                    left={-120}
                    top={80}
                >
                    <Option link={points.dachy_warstwowe[language][0].path}>{points.dachy_warstwowe[language][0].name}</Option>
                    <Option link={points.dachy_warstwowe[language][1].path}>{points.dachy_warstwowe[language][1].name}</Option>
                    <Option link={points.dachy_warstwowe[language][2].path}>{points.dachy_warstwowe[language][2].name}</Option>
                </Point>
                <Point
                    title={points.dachy_izolowane[language][0].title}
                    left={-300}
                    top={-40}
                >
                    <Option link={points.dachy_izolowane[language][0].path}>{points.dachy_izolowane[language][0].name}</Option>
                    <Option link={points.dachy_izolowane[language][1].path}>{points.dachy_izolowane[language][1].name}</Option>
                    <Option link={points.dachy_izolowane[language][2].path}>{points.dachy_izolowane[language][2].name}</Option>
                    <Option link={points.dachy_izolowane[language][3].path}>{points.dachy_izolowane[language][3].name}</Option>
                </Point>
                <Point
                    title={points.sciany_zewnetrzne[language][0].title}
                    left={-135}
                    top={-90}
                >
                    <Option link={points.sciany_zewnetrzne[language][0].path}>{points.sciany_zewnetrzne[language][0].name}</Option>
                    <Option link={points.sciany_zewnetrzne[language][1].path}>{points.sciany_zewnetrzne[language][1].name}</Option>
                </Point>
                <Point
                    title={points.sciany_dzilowe[language][0].title}
                    left={110}
                    top={-75}
                >
                    <Option link={points.sciany_dzilowe[language][0].path}>{points.sciany_dzilowe[language][0].name}</Option>
                </Point>
                <Point
                    title={points.sufity[language][0].title}
                    left={200}
                    top={-20}
                >
                    <Option link={points.sufity[language][0].path}>{points.sufity[language][0].name}</Option>
                    <Option link={points.sufity[language][1].path}>{points.sufity[language][1].name}</Option>
                </Point>
                <Point
                    title={points.posadzki[language][0].title}
                    left={90}
                    top={-190}
                >
                    <Option link={points.posadzki[language][0].path}>{points.posadzki[language][0].name}</Option>
                    <Option link={points.posadzki[language][1].path}>{points.posadzki[language][1].name}</Option>
                </Point>
            </Object>
        </>
    )
}
