import React, { useState } from 'react';
import Carousel from 'react-spring-3d-carousel';

const slides = [
    {
      key: 1,
      content: <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/aplikacja.png" alt="1" />
    },
    {
      key: 2,
      content: <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/aplikacja-2.png" alt="1" />
    },
    {
      key: 3,
      content: <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/aplikacja-3.png" alt="1" />
    },
    {
      key: 4,
      content: <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/NowoczesneDomy.png" alt="1" />
    }
]

const descriptions = [
    "Obiekty przemysłowe",
    "Chłodnie i mroźnie",
    "Obiekty inwentarskie",
    "Nowoczesne domy"
]

const routes = {
    0: {
        pl: {
            path: "/zastosowania/#/obiekty-przemyslowe",
            name: "Obiekty przemysłowe"
        },
        en: {
            path: "/en/usage/#/industrial-facilities",
            name: "Industrial facilities"
        },
        tt: {
            path: "/tt/использование/#/Промышленные-объекты",
            name: "Промышленные объекты"
        }
    },
    1: {
        pl: {
            path: "/zastosowania/#/chlodnie-i-mroznie",
            name: "Chłodnie i mroźnie"
        },
        en: {
            path: "/en/usage/#/coolers-and-freezers",
            name: "COOLERS AND FREEZERS"
        },
        tt: {
            path: "/tt/использование/#/Холодильные-и-морозильные-камерыs",
            name: "Холодильные и морозильные камеры"
        }
    },
    2: {
        pl: {
            path: "/zastosowania/#/obiekty-inwentarskie",
            name: "Obiekty inwentarskie"
        },
        en: {
            path: "/en/usage/#/livestock-buildings",
            name: "Livestock buildings"
        },
        tt: {
            path: "/tt/использование/#/Инвентарные-здания",
            name: "Инвентарные здания"
        }
    },
    3: {
        pl: {
            path: "/nowoczesne-domy",
            name: "Nowoczesne domy"
        },
        en: {
            path: "/en/modern-houses",
            name: "Modern houses"
        },
        tt: {
            path: "/tt/современные дома",
            name: "современные дома"
        }
    }
}

const more = {
    pl: "Zobacz więcej!",
    en: "See more!",
    tt: "Узнать больше"
}

function Sliders() {
    const [slide, setSlide] = useState(0);

    const getLanguage = () => {
        const location = window.location.href;

        if(location.indexOf("/en/") !== -1) 
            return "en";

        if(location.indexOf("/tt/") !== -1) 
            return "tt";

        return "pl";
    }

    const language = getLanguage();  

    const nextImage = () => {
        let nextSlide = slide + 1;

        if(nextSlide >= slides.length) {
            nextSlide = 0;
        }

        setSlide(nextSlide);
    }

    const prevImage = () => {
        let nextSlide = slide - 1;

        if(nextSlide < 0) {
            nextSlide = slides.length - 1;
        }

        setSlide(nextSlide);
    }

    return (
        <div className="slider-container">
            <div className="custom-h3 custom-slider-h2"><h3>{routes[slide][language].name}</h3></div>
            <Carousel slides={slides} offsetRadius={1} goToSlide={slide}/>
            <div className="slider-arrows">
                <img onClick={() => prevImage()} style={{ transform: "rotate(180deg)" }} src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/right-arrow.png"/>
                <img onClick={() => nextImage()} src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/right-arrow.png"/>
            </div>
            <div className="custom-button custom-slider-button" style={{ marginTop: 25 }}><a href={routes[slide][language].path}>{more[language]}</a></div>
        </div>
    );
}

export default Sliders;
