import React from 'react'
import Object from './usage/Object'
import Point from './usage/Point'
import Option from './usage/Option'
import Description from './Description'

export default function Inventory({ language }) {
    const titles = {
        pl: "Obiekty inwentarskie",
        en: "LIVESTOCK BUILDINGS",
        tt: "ИНВЕНТАРНЫЕ ЗДАНИЯ"
    }

    const descriptions = {
        pl: `Podstawowymi czynnikami, które decydują o wydajności i zdrowotności hodowli, są temperatura oraz wilgotność we wnętrzach 
        obiektów inwentarskich. Zapewnienie optymalnego poziomu wymienionych parametrów zapobiega rozwijaniu się groźnych chorób, 
        grzybów i pleśni, wpływając korzystnie na kondycję zwierząt. Idealnym materiałem termoizolacyjnym – przeznaczonym do 
        stosowania we wnętrzach kurników, obór, chlewni czy stajni – jest pianka poliuretanowa, która zapewnia 
        utrzymanie odpowiedniego mikroklimatu.`,
        en: "The basic factors that determine the efficiency and health of farming are temperature and humidity inside livestock buildings. Ensuring the optimal level of these parameters prevents the development of dangerous diseases, fungi and mold, positively affecting the condition of animals. The ideal thermal insulation material - intended for use in the interior of poultry houses, barns, piggery or stable - is polyurethane foam, which ensures the maintenance of an appropriate microclimate.",
        tt: "Основными факторами, определяющими эффективность и здоровье сельского хозяйства, являются температура и влажность внутри животноводческих помещений. Обеспечение оптимального уровня этих параметров предотвращает развитие опасных заболеваний, грибков и плесени, положительно влияющих на состояние животных. Идеальным теплоизоляционным материалом - предназначенным для использования внутри птичников, коровников, свинарников или конюшен - является полиуретановая пена, которая обеспечивает поддержание соответствующего микроклимата."
    }

    const points = {
        dachy_warstwowe: {
            pl: [
                {title: "Dachy z płyt warstwowych", name: "Dach z płyt warstwowych - kalenica", path: "/dach-z-płyt-warstwowych-kalenica/"},
                {name: "Dach z płyt warstwowych - połać", path: "/dach-z-płyt-warstwowych-połać/"},
                {name: "Dach z płyt warstwowych - okap", path: "/dach-z-płyt-warstwowych-okap/"},
            ],
            en: [
                {title: "Roof made of sandwich panels", name: "Roof made of sandwich panels - ridge", path: "/en/Roof-made-of-sandwich-panels-ridge"},
                {name: "Roof made of sandwich panels - slope", path: "/en/roof-made-of-sandwich-panels-slope-2/"},
                {name: "Roof made of sandwich panels - hood", path: "/en/roof-made-of-sandwich-panels-slope/"},
            ],
            tt: [
                {title: "Крыши из сэндвич панелей", name: "Крыша из сэндвич панелей -  конек", path: "/tt/крыша-из-сэндвич-панелей-конек"},
                {name: "Крыша из сэндвич панелей - скат крыши", path: "/tt/крыша-из-сэндвич-панелей---скат-крыши"},
                {name: "Крыша из сэндвич панелей - карниз", path: "/tt/крыша-из-сэндвич-панелей-карниз"},
            ]
        },
        sufity: {
            pl: [
                {title: "Sufit podwieszany", name: "Sufit podwieszany - płyta z frezem TAG lub LAP", path: "/sufit-podwieszany-plyty-z-frezem-tag-lub-lap/"},
                {name: "Sufit podwieszany - płyta z frezem FIT", path: "/sufit-podwieszany-plyty-z-frezem-fit/"},
            ],
            en: [
                {title: "Ceiling", name: "Suspended ceiling - boards with a TAG or LAP milling", path: "/en/suspended-ceiling---boards-with-a-tag-or-lap-milling"},
                {name: "Suspended ceiling - boards with a FIT milling", path: "/en/suspended-ceiling---boards-with-a-fit-milling"},
            ],
            tt: [
                {title: "Подвесные потолки", name: "Подвесной потолокg - панели с фрезой TAG или LAP", path: "/tt/подвесной-потолокg-панели-с-фрезой-tag-ил"},
                {name: "Подвесной потолок - панели с фрезой FIT", path: "/tt/подвесной-потолок---панели-с-фрезой-fit"},
            ],
        },
        posadzki: {
            pl: [
                {title: "Posadzki", name: "Podłoga na gruncie - izolacja dwuwarstwowa", path: "/podloga-na-gruncie-izolacja-dwuwarstwowa/"},
                {name: "Podłoga na gruncie - izolacja jednowarstwowa", path: "/podloga-na-gruncie-izolacja-jednowarstwowa/"},
            ],
            en: [
                {title: "Floor on the ground", name: "Floor on the ground - double layer insulation", path: "/en/floor-on-the-ground---double-layer-insulation"},
                {name: "Floor on the ground - single layer insulation", path: "/en/floor-on-the-ground---single-layer-insulation"},
            ],
            tt: [
                {title: "Полы", name: "Пол на земле - двухслойная изоляция", path: "/tt/пол-на-земле---двухслойная-изоляция"},
                {name: "Пол на земле - однослойная изоляция", path: "/tt/пол-на-земле---однослойная-изоляция"},
            ]
        },
        sciany_zewnetrzne: {
            pl: [
                {title: "Ściany zewnętrzne", path: "/sciany-zewnetrzne-obiektow-inwentarskich/", name: "Ściany zewnętrzne obiektów inwentarskich"}
            ],
            en: [
                {title: "External walls", path: "/en/outer-walls-of-livestock-facilities", name: "Outer walls of livestock facilities"}
            ],
            tt: [
                {title: "Наружные стены", path: "/tt/наружные-стены-животноводческих-пом", name: "Наружные стены животноводческих помещений"}
            ]
        },
        dach: {
            pl: [
                {title: "Dachy izolowane panelami termoizolacyjnymi PIR", name: "Dach na warstwie nośnej z blach stalowych", path: "/dach-na-warstwie-nosnej-z-blach-stalowych/"},
                {name: "Dach na podłożu betonowym", path: "/dach-na-podlozu-betonowym/"},
            ],
            en: [
                {title: "Roof on a supporting layer of steel sheets", name: "Roof on a supporting layer", path: "/en/Roof on a supporting layer"},
                {name: "Roof on a concrete base", path: "/en/Roof-on-a-concrete-base"},
            ],
            tt: [
                {title: "Крыши с изолированные теплоизоляционными панелями termPIR", name: "Кровля на несущем слое из стальных листов", path: "/tt/кровля-на-несущем-слое-из-стальных-лис"},
                {name: "Крыша на бетонном основании", path: "/tt/крыша-на-бетонном-основании"},
            ]
        }
    }

    return (
        <>
            <Description>
                <h1>{titles[language]}</h1>
                <p>{descriptions[language]}</p>
            </Description>
            <Object
                languge={language}
                image="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/obiekty-inwentarskie.png"
            >
                <Point
                    title={points.dachy_warstwowe[language][0].title}
                    left={-150}
                    top={60}
                >
                    <Option link={points.dachy_warstwowe[language][0].path}>{points.dachy_warstwowe[language][0].name}</Option>
                    <Option link={points.dachy_warstwowe[language][1].path}>{points.dachy_warstwowe[language][1].name}</Option>
                    <Option link={points.dachy_warstwowe[language][2].path}>{points.dachy_warstwowe[language][2].name}</Option>
                </Point>
                <Point
                    title={points.dach[language][0].title}
                    left={-370}
                    top={0}
                >
                    <Option link={points.dach[language][0].path}>{points.dach[language][0].name}</Option>
                    <Option link={points.dach[language][1].path}>{points.dach[language][1].name}</Option>
                </Point>
                <Point
                    title={points.sciany_zewnetrzne[language][0].title}
                    left={-150}
                    top={-130}
                >
                    <Option link={points.sciany_zewnetrzne[language][0].path}>{points.sciany_zewnetrzne[language][0].name}</Option>
                </Point>
                <Point
                    title={points.sufity[language][0].title}
                    left={130}
                    top={10}
                >
                    <Option link={points.sufity[language][0].path}>{points.sufity[language][0].name}</Option>
                    <Option link={points.sufity[language][1].path}>{points.sufity[language][1].name}</Option>
                </Point>
                <Point
                    title={points.posadzki[language][0].title}
                    left={100}
                    top={-180}
                >
                    <Option link={points.posadzki[language][0].path}>{points.posadzki[language][0].name}</Option>
                    <Option link={points.posadzki[language][1].path}>{points.posadzki[language][1].name}</Option>
                </Point>
            </Object>
        </>
    )
}
