import React from 'react'
import Object from './usage/Object'
import Point from './usage/Point'
import Option from './usage/Option'
import Description from './Description'

export default function Cooling({ language }) {
    const titles = {
        pl: "Chłodnie i mroźnie",
        en: "COOLERS AND FREEZERS",
        tt: "ХОЛОДИЛЬНЫЕ И МОРОЗИЛЬНЫЕ КАМЕРЫ"
    }

    const descriptions = {
        pl: `Chłodnie i mroźnie to obiekty o specyficznym mikroklimacie, przeznaczone do przechowywania i przerabiania produktów
        spożywczych oraz innych wyrobów specjalnego przeznaczenia (np. z branży chemiczno-farmaceutycznej). Utrzymanie
        właściwej temperatury wewnętrznej to najważniejszy cel, jaki przyświeca projektantom tego typu obiektów.`,
        en: "Cold stores and freezers are facilities with a specific microclimate, intended for storage and processing of food products and other special-purpose products (e.g. from the chemical and pharmaceutical industry). Maintaining the right internal temperature is the most important goal that guides the designers of this type of objects.",
        tt: "Холодильные  и морозильные камеры это объекты со специфическим микроклиматом, предназначенные для хранения и переработки пищевых продуктов и других продуктов специального назначения (например, из химической и фармацевтической промышленности). Поддержание правильной внутренней температуры является наиболее важной целью, которой руководствуются разработчики этого типа объектов"
    }

    const points = {
        sufity: {
            pl: [
                {title: "Sufity", name: "Sufit chłodni/mroźni", path: "/sufit-chlodni-mrozni/"},
            ],
            en: [
                {title: "Ceilings made of sandwich panels", name: "Cold storage / freezer ceiling", path: "/en/cold-storage-freezer-ceiling"},
            ],
            tt: [
                {title: "Потолки", name: "Потолок холодной /морозильной камеры", path: "/tt/потолок-холодной-морозильной-камеры"},
            ]
        },
        sciany_zewnetrzne: {
            pl: [
                {title: "Ściany zewnętrzne", name: "Ściany zewnętrzne chłodni/mroźni - widok z wewnątrz", path: "/sciany-zewnetrzne-chlodni-mrozni-widok-z-wewnatrz/"},
                {name: "Ściany zewnętrzne chłodni/mroźni - widok z zewnętrz", path: "/sciany-zewnetrzne-chlodni-mrozni-widok-z-zewnatrz/"},
            ],
            en: [
                {title: "External walls", name: "Exterior walls of the cold store / freezer - view from the inside", path: "/en/exterior-walls-of-the-cold-store-freezer---view-from-the-inside"},
                {name: "Exterior walls of the cold store / freezer - view from the outside", path: "/en/exterior-walls-of-the-cold-store-freezer---view-from-the-outside"},
            ],
            tt: [
                {title: "Наружные стены", name: "Наружные стены холодильной/ морозильной камеры - вид изнутри", path: "/tt/наружные-стены-холодильной-морозиль"},
                {name: "Наружные стены холодильной / морозильной камеры - вид снаружи", path: "/tt/наружные-стены-холодильной-морозиль-2"},
            ]
        },
        posadzki: {
            pl: [
                {title: "Posadzki", name: "Podłoga na gruncie - izolacja podłogi chłodni/mroźni", path: "/podloga-na-gruncie-izolacja-podlogi-chlodni-mrozni/"},
            ],
            en: [
                {title: "Floor on the ground", name: "Floor on the ground - cold store / freezer floor insulation", path: "/en/floor-on-the-ground---cold-store-freezer-floor-insulation"},
            ],
            tt: [
                {title: "Полы", name: "Пол на земле - изоляция пола холодильной/ морозильной камеры", path: "/tt/пол-на-земле-изоляция-пола-холодильно"},
            ]
        }
    }

    return (
        <>
            <Description>
                <h1>{titles[language]}</h1>
                <p>{descriptions[language]}</p>
            </Description>
            <Object
                languge={language}
                image="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/chlodnie.png"
            >
                <Point
                    title={points.sufity[language][0].title}
                    left={-110}
                    top={90}
                >
                    <Option link={points.sufity[language][0].path}>{points.sufity[language][0].name}</Option>
                </Point>
                <Point
                    title={points.sciany_zewnetrzne[language][0].title}
                    left={-450}
                    top={-40}
                >
                    <Option link={points.sciany_zewnetrzne[language][0].path}>{points.sciany_zewnetrzne[language][0].name}</Option>
                    <Option link={points.sciany_zewnetrzne[language][1].path}>{points.sciany_zewnetrzne[language][1].name}</Option>
                </Point>
                <Point
                    title={points.posadzki[language][0].title}
                    left={-150}
                    top={-165}
                >
                    <Option link={points.posadzki[language][0].path}>{points.posadzki[language][0].name}</Option>
                </Point>
            </Object>
        </>
    )
}
