import React from 'react';
import {
    HashRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import UsageMain from './UsageMain';
import IndustrialFacilities from './IndustrialFacilities';
import Cooling from './Cooling';
import Inventory from './Inventory';

const routes = {
    industrial: {
        pl: {
            path: "/obiekty-przemyslowe",
            name: "Obiekty przemysłowe"
        },
        en: {
            path: "/industrial-facilities",
            name: "Industrial facilities"
        },
        tt: {
            path: "/Промышленные-объекты",
            name: "Промышленные объекты"
        }
    },
    coolers: {
        pl: {
            path: "/chlodnie-i-mroznie",
            name: "Chłodnie i mroźnie"
        },
        en: {
            path: "/coolers-and-freezers",
            name: "COOLERS AND FREEZERS"
        },
        tt: {
            path: "/Холодильные-и-морозильные-камерыs",
            name: "Холодильные и морозильные камеры"
        }
    },
    livestock: {
        pl: {
            path: "/obiekty-inwentarskie",
            name: "Obiekty inwentarskie"
        },
        en: {
            path: "/livestock-buildings",
            name: "Livestock buildings"
        },
        tt: {
            path: "/Инвентарные-здания",
            name: "Инвентарные здания"
        }
    },
    home: {
        pl: {
            path: "/nowoczesne-domy/",
            name: "Nowoczesne domy"
        },
        en: {
            path: "/modern-houses/",
            name: "Modern houses"
        },
        tt: {
            path: "/современные-дома/",
            name: "современные дома"
        },
    }
}

export default function Usage() {
    const getLanguage = () => {
        const location = window.location.href;

        if(location.indexOf("/en/") !== -1) 
            return "en";

        if(location.indexOf("/tt/") !== -1) 
            return "tt";

        return "pl";
    }

    const language = getLanguage();  

    return (
        <div className="usage">
            <Router>
                <div className="main-usage">
                    <div className="objects">
                        <Link to={routes.industrial[language].path}>
                            <div className="item"><img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/img1.png"/>{routes.industrial[language].name}</div>
                        </Link>
                        <Link to={routes.coolers[language].path}>
                            <div className="item"><img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/img2.png"/>{routes.coolers[language].name}</div>
                        </Link>
                        <Link to={routes.livestock[language].path}>
                            <div className="item"><img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/img3.png"/>{routes.livestock[language].name}</div>
                        </Link>
                        <a href={routes.home[language].path}>
                            <div className="item"><img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/img4.png"/>{routes.home[language].name}</div>
                        </a>
                    </div>
                </div>
                <Route exact path="/" render={props => <UsageMain {...props} language={language}/>}/>
                <Route path={routes.industrial[language].path} render={props => <IndustrialFacilities {...props} language={language}/>}/>
                <Route path={routes.coolers[language].path} render={props => <Cooling {...props} language={language}/>}/>
                <Route path={routes.livestock[language].path} render={props => <Inventory {...props} language={language}/>}/>
            </Router>
        </div>
    )
}
