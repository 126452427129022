import React, { useState } from 'react'

export default function Point({ title, left, top, children }) {
    const [info, setInfo] = useState(false);
    const [options, setOptions] = useState(false)

    return (
        <div className="point" style={{ marginLeft: left, marginBottom: top }} onMouseLeave={() => setInfo(false)} onMouseEnter={() => setInfo(true)}>
            <img onClick={() => setOptions(!options)} src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/cube.png"/>
            <div className={`title ${info && "active"}`}>{title}</div>
            <div className={`options ${info && "active"}`}>{children}</div>
        </div>
    )
}
