import React from 'react'

export default function UsageMain({ language }) {   
    const descriptions = [
        {
            pl: "Ogromny wybór<br/>akcesoriów peryferyjnych",
            en: "Huge selection <br/>of peripheral accessories",
            tt: "Огромный выбор периферийных аксессуаров"
        },
        {
            pl: "Trwałość<br/>i niezawodność",
            en: "Durability <br/> and reliability",
            tt: "Долговечность <br/> и надежность"
        },
        {
            pl: "Różnorodne systemy<br/>i materiały konstrukcyjne",
            en: "Various systems<br/> and materials of construction",
            tt: "Различные системы <br/>и материалы строительства"
        },
        {
            pl: "Dostosowanie do ekstremalnych warunków<br/>panujących w obiektach przemysłowych",
            en: "Adaptation to extreme conditions <br/> prevailing in industrial facilities",
            tt: "Адаптация к экстремальным условиям <br/> преобладающим на промышленных объектах"
        },
        {
            pl: "Najwyższe parametry<br/>termoizolacyjne",
            en: "The highest thermal<br/> insulation parameters",
            tt: "Самые высокие <br/>показатели теплоизоляции"
        },
        {
            pl: "Najbardziej atrakcyjne rozwiązanie<br/>finansowe dla inwestycji",
            en: "The most attractive <br/>financial solution for investments",
            tt: "Самое привлекательное <br/>финансовое решение для инвестиций"
        },
        {
            pl: "Bezawaryjność i długie lata <br/>żywotności",
            en: "Reliability<br/> and long life",
            tt: "Надежность <br/>и долговечность"
        },
        {
            pl: "Ekologiczne i przyjazne środowisku<br/>naturalnemu",
            en: "Ecological <br/>and environmentally friendly",
            tt: "Экологичный <br/>и экологически чистый"
        },
    ]

    return (
        <div className="main-usage">
            <div className="pros">
                <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/bud.png"/>
                <div style={{ left: "20%", bottom: "15%" }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (1).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[0][language] }}></p>
                </div>
                <div style={{ left: "10%", bottom: "40%" }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (2).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[1][language] }}></p>
                </div>
                <div style={{ right: "10%", bottom: "40%" }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (3).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[2][language] }}></p>
                </div>
                <div style={{ left: "50%", top: "10%", marginLeft: -150 }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (4).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[3][language] }}></p>
                </div>
                <div style={{ right: "20%", top: "20%" }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (5).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[4][language] }}></p>
                </div>
                <div style={{ right: "20%", bottom: "15%" }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (6).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[5][language] }}></p>
                </div>
                <div style={{ left: "20%", top: "20%" }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (7).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[6][language] }}></p>
                </div>
                <div style={{ left: "50%", bottom: "5%", marginLeft: -150 }} className="icon">
                    <img src="https://marpanel.pl/wp-content/themes/septera-child/resources//images/common/ikony (8).png"/>
                    <p dangerouslySetInnerHTML={{ __html: descriptions[7][language] }}></p>
                </div>
            </div>
        </div>
    )
}
