import React from 'react'
import { Link } from 'react-router-dom'

export default function Object({ children, image, languge }) {
    const descriptions = {
        pl: `Kliknij na wybrany element <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/cube.png"/> , aby wyświetlić rozwiązania w zastosowaniu naszych produktów.`,
        en: `Click on the selected element <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/cube.png"/> to display solutions in the application of our products.`,
        tt: `Нажмите на выбранный элемент <img src="https://marpanel.pl/wp-content/themes/septera-child/resources/images/common/cube.png"/> , чтобы отобразить решения в области применения наших продуктов.`
    }

    return (
        <div className="object">
            <div className="super" dangerouslySetInnerHTML={{ __html: descriptions[languge] }}></div>
            <img src={image}/>
            {children}
        </div>
    )
}
