import React from 'react';
import ReactDOM from 'react-dom';

import Sliders from './Sliders';
import Usage from './Usage';

import './App.scss';

const components = {
    'slider-homepage': <Sliders />,
    'usage': <Usage/>
}

const root = document.getElementById('root');
ReactDOM.render(components[root.dataset.componentName], root);
