import React from 'react'

export default function Option({ children, link }) {
    return (
        <a href={`https://marpanel.pl${link}`}>
            <div className="option">
                {children}
            </div>
        </a>
    )
}
